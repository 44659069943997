import React, { useRef, useState, useEffect } from 'react'
import { gsap } from 'gsap'

import useStore from '../helper/store'

import '../preloader.css'

const Preloader = () => {
  const [progress, setProgress] = useState('0')
  const number = useRef()
  const preloader = useRef()
  const tl = useRef()
  const tlx = useRef()
  const tlNumber = useRef()
  const { loading, setLoading } = useStore()

  // Effect to handle initial file loading (runs only once)
  useEffect(() => {
    const formatProgress = (value) => (value < 10 ? `0${value}` : `${value}`)

    const fileUrls = ['/img/1691.webp', '/img/car_door.webp', '/img/car_empty.webp', '/img/wheel.webp']
    const total = fileUrls.length
    let loaded = 0

    const handleLoad = () => {
      loaded++
      const progressValue = Math.round((loaded / total) * 100)
      setProgress(formatProgress(progressValue)) // Update progress state
      if (loaded === total) {
        console.log('All files loaded')

        // Animate the preloader fading out
        tlx.current = gsap.to(preloader.current, {
          opacity: 0
        })

        // Only animate `.myCanvas` if the URL is `/`
        if (window.location.pathname === '/') {
          gsap.fromTo(
            '.myCanvas', // Target element
            { scale: 1.5, opacity: 0 }, // Starting properties
            { scale: 1.0, opacity: 1, delay: 0.5, duration: 1.3 } // Ending properties
          )
        }
      }
    }

    // Simulate loading files
    fileUrls.forEach((url) => {
      const file = new Image()
      file.onload = handleLoad
      file.src = url
    })

    return () => {
      if (tlx.current) tlx.current.kill()
    }
  }, []) // Empty dependency array ensures this effect runs only once

  // Effect to handle animations triggered by `loading` state changes
  useEffect(() => {
    if (!loading) {
      tlNumber.current = gsap.to(number.current.children, {
        marginLeft: '110px',
        alignSelf: 'flex-end',
        fontSize: '20px',
        duration: 0.8,
        delay: 0.3,
        transformOrigin: '100% 0%',
        ease: 'circ.inOut'
      })
    }

    return () => {
      if (tlNumber.current) tlNumber.current.kill()
    }
  }, [loading]) // Runs whenever `loading` changes

  return (
    <div ref={preloader} className="preloader">
      <div className="logo">
        <a href="/">
          <img src="img/Logo.png" alt="Logo" />
        </a>
      </div>
      <span className="pre-number" ref={number}>
        {progress}%
      </span>
    </div>
  )
}

export default Preloader
