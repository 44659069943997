import { extend, useFrame } from '@react-three/fiber'
import { shaderMaterial } from '@react-three/drei'
import { useRef } from 'react'

// Shader material adapted for React Three Fiber
const SandboxShaderMaterialThree = shaderMaterial(
  {
    time: 0,
    resolution: [1, 1]
  },
  // Vertex Shader
  `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
  `,
  // Fragment Shader
  `
  #ifdef GL_ES
  precision highp float;
  #endif

  uniform float time;
  uniform vec2 resolution;

  #define iTime time
  #define iResolution resolution

  vec3 tonemap(vec3 v) {
      return mix(v, vec3(1.), smoothstep(1., 4., dot(v, vec3(1.))));
  }

  float f1(float x, float offset, float freq) {
      return .84 * sin(radians(20.) * x + offset) + .01 * sin(freq * x);
  }

  void mainImage(out vec4 fragColor, in vec2 fragCoord) {
      float scale = iResolution.y;
      vec2 uv = (1.85 * fragCoord - iResolution.xy) / scale;

      vec3 col = vec3(0);

      float offsets[3];
      offsets[0] = 0. * radians(360.) / 3.;
      offsets[1] = 1. * radians(360.) / 3.;
      offsets[2] = 2. * radians(360.) / 3.;

      float freqs[3];
      freqs[0] = radians(160.);
      freqs[1] = radians(213.);
      freqs[2] = radians(186.);

      for (int i = 0; i < 3; ++i) {
          float x = uv.x + 4. * iTime;
          float y = f1(x, offsets[i], freqs[i]);
          float uv_x = min(uv.x, 1. + .4 * sin(radians(210.) * iTime + radians(360.) * float(i) / 3.));

          float r = uv.x / 40.;
          float d1 = length(vec2(uv_x, y) - uv) - r;

          // Updated color: #E6FF24
          vec3 customColor = vec3(0.902, 1.0, 0.141); // RGB for #E6FF24
          col += 1. / pow(max(1., d1 * scale), .8 + .1 * sin(radians(245.) * iTime + radians(180.) * float(i) / 3.))
              * customColor;
      }

      fragColor = vec4(tonemap(col), 1.);
  }

  void main(void) {
      mainImage(gl_FragColor, gl_FragCoord.xy);
  }
  `
)

// Extend the shader material
extend({ SandboxShaderMaterialThree })

function SandboxPlaneThree() {
  const materialRef = useRef()

  // Update uniforms dynamically
  useFrame((state) => {
    const { clock, size } = state
    if (materialRef.current) {
      materialRef.current.time = clock.getElapsedTime()
      materialRef.current.resolution = [size.width, size.height]
    }
  })

  return (
    <>
      <mesh position={[0, -1, 0]}>
        <planeGeometry args={[16, 12]} />
        <sandboxShaderMaterialThree ref={materialRef} />
      </mesh>
    </>
  )
}

export default SandboxPlaneThree
