import { create } from 'zustand'

const useStore = create((set) => ({
  // Initial state
  count: 0,
  loading: true,
  setLoading: (isLoading) => set({ loading: isLoading }) // Action to update loading

  // // Actions
  // increment: () => set((state) => ({ count: state.count + 1 })),
  // decrement: () => set((state) => ({ count: state.count - 1 }))
}))

export default useStore
