import { useRef } from 'react'

export function NeonRectangle({ color = '#000000', intensity = 2, ...props }) {
  const meshRef = useRef()

  // Optionally, rotate the rectangle slightly over time

  return (
    <>
      <mesh position={[0, -1.4, 0.01]} ref={meshRef} {...props}>
        <planeGeometry args={[25, 0.05]} /> {/* Adjust args to change rectangle size */}
        <meshStandardMaterial
          color={color}
          emissive={color}
          emissiveIntensity={intensity} // Control the glow intensity
        />
      </mesh>

      {/* Add bloom effect */}
      {/* <EffectComposer>
        <Bloom
          intensity={1.5} // Adjust intensity of the bloom effect
          luminanceThreshold={0.2} // Threshold to control what part of the object blooms
          luminanceSmoothing={0.9} // Controls the smoothness of bloom transition
        />
      </EffectComposer> */}
    </>
  )
}
