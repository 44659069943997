import React, { useState, useRef, useEffect } from 'react'
import Modal from 'react-modal'
import gsap from 'gsap'

// Set the root element for accessibility (required by react-modal)
Modal.setAppElement('#root')

const CustomButton = ({ label = 'Book Now', type = 'primary', urlSegment = '' }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const modalRef = useRef(null)
  const linkRef = useRef(null)
  const iframeUrl = `https://wwwmilesnmic.simplybook.me/${urlSegment}`

  useEffect(() => {
    // Create link element for prefetching
    const link = document.createElement('link')
    link.rel = 'prefetch'
    link.as = 'document'  // Specify the resource type
    link.href = iframeUrl
    linkRef.current = link

    // Add to head when component mounts
    document.head.appendChild(link)

    // Cleanup when component unmounts
    return () => {
      if (linkRef.current && document.head.contains(linkRef.current)) {
        document.head.removeChild(linkRef.current)
      }
    }
  }, [iframeUrl])

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    gsap.to(modalRef.current, {
      opacity: 0,
      scale: 0.8,
      duration: 0.3,
      onComplete: () => setModalIsOpen(false)
    })
  }

  useEffect(() => {
    if (modalIsOpen) {
      gsap.set(modalRef.current, { opacity: 0, scale: 0.8 })
      gsap.to(modalRef.current, { opacity: 1, scale: 1, duration: 0.5, ease: 'power3.out' })
      // Prevent body scroll
      document.body.style.overflow = 'hidden'
    } else {
      // Restore body scroll
      document.body.style.overflow = ''
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = ''
    }
  }, [modalIsOpen])

  return (
    <>
      <button 
        style={type === 'primary' ? styles.primaryButton : styles.secondaryButton} 
        onClick={openModal}
      >
        {label}
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Booking Modal"
        style={{
          overlay: {
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          content: {
            width: '95%',
            height: '95%',
            margin: 'auto',
            zIndex: 1001,
            position: 'relative',
            display: 'flex',
            padding: '0',
            alignItems: 'center',
            justifyContent: 'center',
            inset: '0',
            overflow: 'hidden'
          }
        }}>
        <div ref={modalRef} style={{ width: '100%', height: '100%' }}>
          <button
            onClick={closeModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'none',
              border: 'none',
              fontSize: '24px',
              fontWeight: 'bold',
              cursor: 'pointer',
              color: '#333'
            }}>
            &times;
          </button>
          <iframe
            src={iframeUrl}
            width="100%"
            height="100%"
            title="Booking"
            frameBorder="0"></iframe>
        </div>
      </Modal>
    </>
  )
}

const styles = {
  primaryButton: {
    backgroundColor: '#AA5DF9',
    color: '#FFFFFF',
    padding: '15px 30px',
    marginTop: '16px',
    fontSize: '16px',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
    transition: 'opacity 0.3s'
  },
  secondaryButton: {
    backgroundColor: '#242424',
    color: '#FFFFFF',
    padding: '15px 30px',
    marginTop: '16px',
    fontSize: '16px',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
    transition: 'opacity 0.3s'
  }
}

// Add hover effect with a slight opacity change
styles.primaryButton[':hover'] = {
  opacity: 0.9
}

styles.secondaryButton[':hover'] = {
  opacity: 0.9
}

export default CustomButton
