import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './ImageGallery.css'

gsap.registerPlugin(ScrollTrigger)

const importAllImages = () => {
  const images = []
  for (let i = 1; i <= 12; i++) {
    const imageNumber = i.toString().padStart(2, '0')
    images.push({
      src: require(`./img/content/image${imageNumber}.webp`),
      loading: "lazy",
      srcSet: `
        ./img/content/image${imageNumber}-300.webp 300w,
        ./img/content/image${imageNumber}-600.webp 600w,
        ./img/content/image${imageNumber}.webp 1200w
      `
    })
  }
  return images
}

const ImageGallery = () => {
  const galleryRef = useRef(null)

  useEffect(() => {
    const gallery = galleryRef.current
    const images = gallery.querySelectorAll('.image')

    const updateClasses = () => {
      const isMobile = window.innerWidth <= 768
      images.forEach((image, index) => {
        image.classList.remove('first', 'second', 'third')
        if (isMobile) {
          if (index % 2 === 0) {
            image.classList.add('first')
          } else {
            image.classList.add('third')
          }
        } else {
          if (index % 3 === 0) {
            image.classList.add('first')
          } else if (index % 3 === 1) {
            image.classList.add('second')
          } else {
            image.classList.add('third')
          }
        }
      })
    }

    const applyAnimations = () => {
      const isMobile = window.innerWidth <= 768
      images.forEach((image) => {
        const duration = isMobile ? 0.5 : 2.5 // Reduced duration for mobile
        const xValue = isMobile ? 25 : 150 // Reduced movement for mobile
        const rotateZ = isMobile ? 5 : 25 // Reduced rotation for mobile
        const rotateX = isMobile ? 3 : 15 // Reduced rotation for mobile

        const  animationSettings = {
          start: 'top bottom',
          end: '=+150',
          scrub: isMobile ? 0.5 : true, // Add specific scrub value for mobile
          toggleActions: 'play none none none',
          preventOverlaps: true, // Prevent animation conflicts
          fastScrollEnd: true, // Better performance for fast scrolling
        }

        if (image.classList.contains('first')) {
          gsap.fromTo(
            image,
            { y: 100, scale: 0.7, rotateZ: -rotateZ, rotateX, x: -xValue, opacity: 0 },
            { y: 0, scale: 1, rotateZ: 0, rotateX: 0, x: 0, opacity: 1, duration, scrollTrigger: { trigger: image, ...animationSettings } }
          )
        } else if (image.classList.contains('second')) {
          gsap.fromTo(
            image,
            { y: 100, opacity: 0, scale: 0.7 },
            { y: 0, opacity: 1, scale: 1, duration, scrollTrigger: { trigger: image, ...animationSettings } }
          )
        } else if (image.classList.contains('third')) {
          gsap.fromTo(
            image,
            { y: 100, scale: 0.7, rotateZ: rotateZ, rotateX: -rotateX, x: xValue, opacity: 0 },
            { y: 0, scale: 1, rotateZ: 0, rotateX: 0, x: 0, opacity: 1, duration, scrollTrigger: { trigger: image, ...animationSettings } }
          )
        }
      })
    }

    const initGallery = () => {
      updateClasses()
      applyAnimations()
      ScrollTrigger.refresh()
    }

    initGallery()
    window.addEventListener('resize', initGallery)

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill())
      window.removeEventListener('resize', initGallery)
    }
  }, [])

  const images = importAllImages()

  return (
    <div className="sliding-gallery-wrapper">
      <div className="sliding-gallery" ref={galleryRef}>
        {images.map((image, index) => (
          <img key={index} src={image.src} alt={`Slide ${index + 1}`} className="image" />
        ))}
      </div>
    </div>
  )
}

export default ImageGallery
